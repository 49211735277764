
import Vue from "vue";
import {commentMinLength} from "@/config";

export default Vue.extend({
  name: "RequestReviewerChangeModal",
  computed: {
    messagePercentage(): number {
      return Math.min((+this.message.length) / commentMinLength * 100, 100) || 0;
    },
    visible: {
      get(): boolean {
        return this.$store.state.modals.requestReviewerChange.visible;
      },
      async set(visible: boolean): Promise<void> {
        await this.$store.dispatch("modals/set", {
          type: "requestReviewerChange",
          visible
        });
      }
    },
    info(): any {
      return  this.$store.state.modals.requestReviewerChange.data;
    }
  },
  data() {
    return {
      message: ""
    };
  },
  methods: {
    async requestReviewerChange() {
      // validation
      if (this.message.length === 0) {
        await this.$message.error("Пожалуйста, заполните это поле");
        return;
      }
      if (this.message.length < commentMinLength) {
        await this.$message.error("Пожалуйста, предоставьте больше информации");
        return;
      }

      await this.$store.dispatch("review/requestReviewerChange", {
        badReviewerId: this.info.badReviewerId,
        level: this.info.level,
        message: this.message
      });
      this.visible = false;
    },
  }
});
