
import Vue, {PropOptions} from "vue";
import LevelReviewsTable from "@/components/level/LevelReviewsTable.vue";
import {Id, ReviewInfo, ReviewParticipant, SystemConfig, BaseUser} from "@/types.d";
import LevelReviewDownloadHomework from "@/components/level/LevelReviewDownloadHomework.vue";
import ReviewTypeModal from "@/components/modals/ReviewTypeModal.vue";
import { formatPhone } from "@/utils";

export default Vue.extend({
  name: "LevelReviewParticipant",
  computed: {
    homeWorks(): string[] {
      return this.reviewType === "get"
          ? this.$store.state.levels.all[this.level - 1].homeworks || []
          : this.participant.homeworks || [];
    },
    contact(): BaseUser {
      return this.$store.state.user.users[this.participant.id] || {};
    },
    config(): SystemConfig {
      return this.$store.state.config;
    },
    userRole(): string {
      return this.reviewType === "get" ? "examinee" : "reviewer";
    },
    participantRole(): string {
      return this.reviewType === "get" ? "reviewer" : "examinee";
    },
    isBestReviewer(): boolean {
      return +this.bestReviewer === +this.participant.id;
    },
    isVolunteer(): boolean {
      return this.$store.state.user.me.role.includes("volunteer");
    },
    userLevel(): number {
      return this.isVolunteer
        ? this.$store.state.user.me.level
        : this.level;
    },
    phone(): string {
      return formatPhone(this.contact.phone || "");
    },
    lastActiveReviewTab: {
      get(): string {
        //  i am not sure if it is really necessary to compare active review with homeworks.length
        //  because it would be strange if user had uploaded more homeworks, than reviews passed
        return this.activeReviewTab || `${Math.min(this.activeReview, this.homeWorks.length - 1)}`;
      },
      set(value: string) {
        this.activeReviewTab = value;
      }
    }
  },
  components: {
    LevelReviewsTable,
    LevelReviewDownloadHomework,
    ReviewTypeModal
  },
  data() {
    return {
      activeReviewTab: "",
      reviewerChange: {
        modalVisible: false,
        message: ""
      }
    };
  },
  methods: {
    reviewIsWorthToConduct(index: number): boolean {
      // if active review is more then index, the index's review is guaranteed to be passed
      // e.g. active review is 1, index is 0. user have to pass review 0 to be able to pass review 1
      return this.activeReview > index
        ? true
        // if index is current review, then check if homework is present
        : this.activeReview === index
          ? !!this.homeWorks[index]
          // else, e.g. when index is bigger than active review, then definitely it is too early
          : false;
    },
    async showReviewStartModal(reviewNumber: number) {
      await this.$store.dispatch("modals/set", {
        type: "review",
        visible: true,
        data: {
          reviewNumber,
          authorLevel: this.userLevel,
          receiverId: this.participant.id,
        }
      });
    },
    async showModal(data: any) {
      await this.$store.dispatch("modals/set", {
        type: "evaluation",
        data,
        visible: true
      });
    },
    async startReview(reviewNumber: number) {
      await this.$store.dispatch("review/start", {
        reviewerLevel: this.userLevel,
        examineeId: this.participant.id,
        reviewNumber
      });
      this.showReviewStartModal(reviewNumber);
    },
    gotAtLeastOneReview(reviews: ReviewInfo[]): boolean {
      return reviews.filter((r: ReviewInfo): boolean => !!r.started).length > 0;
    },
    getReviewTabTitle(index: number): string {
      const reviewDisableReason = this.reviewIsWorthToConduct(index) ? "" : `(${this.$t("level.emptyCode")})`;
      return `${this.$t("level.review", {number: index + 1})} ${reviewDisableReason}`;
    },
    async toggleBestReviewer(e: Event) {
      e.stopPropagation();
      const status: string = this.isBestReviewer ? "cancel" : "choose";

      if (this.isBestReviewer) {
        await this.$store.dispatch("review/chooseBestReviewer", {
          userId: this.bestReviewer,
          level: this.userLevel,
          status: "cancel"
        });
      }
      await this.$store.dispatch("review/chooseBestReviewer", {
        userId: this.participant.id,
        level: this.userLevel,
        status
      });
    },
    async requestReviewerChangeModal(e: Event) {
      e.stopPropagation();
      await this.$store.dispatch("modals/set", {
        type: "requestReviewerChange",
        visible: true,
        data: {
          badReviewerId: this.participant.id,
          level: this.userLevel
        }
      });
    },
    async goToUsersDiscord(e: Event) {
      e.stopPropagation();
      window.open(this.contact.discord);
    },
    async callUser(e: Event) {
      e.stopPropagation();
      window.location.href =  "tel:" + this.phone;
    }
  },
  // i don't know why this component requires to convert to PropOptions all the props. It's a mad world
  props: {
    // "get" or "set" relative to user. "set" is when the user is reviewer
    reviewType: {
      type: String,
      required: true
    } as PropOptions<string>,
    level: {
      type: Number,
      required: true
    } as PropOptions<number>,
    activeReview: {
      type: Number,
      required: true
    } as PropOptions<number>,
    reviewerIndex: {
      type: Number,
      default: () => 0
    } as PropOptions<number>,
    levelClosed: {
      type: Boolean,
      default: () => false
    } as PropOptions<boolean>,
    participant: {
      type: Object as () => ReviewParticipant,
      required: true
    } as PropOptions<ReviewParticipant>,
    bestReviewer: {
      type: Number as () => Id,
      default() {
        return 0;
      }
    } as PropOptions<number>
  }
});
