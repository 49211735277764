
import Vue from "vue";
import {ReviewRegistration} from "@/types.d";
import MarkedLink from "@/components/MarkedLink.vue";
import {links} from "@/config";

export default Vue.extend({
  name: "LevelReviewRegistrationVolunteer",
  components: {
    MarkedLink
  },
  computed: {
    links() {
      return links;
    },
    registrationAvailable() {
      return this.type === "future" && this.registrationOpened && !this.registrationClosed;
    },
    systemInOnlineMode(): boolean {
      return this.$store.state.config.online;
    },
    level(): number {
      return this.$store.state.user.me.level;
    },
    registrationOpened(): boolean {
      return true; // this.level.day >= this.$store.state.config.reviewRegistrationPeriod.startDay;
    },
    registrationClosed(): boolean {
      return false; // this.level.day > this.$store.state.config.reviewRegistrationPeriod.finishDay;
    },
    confirmed: {
      get(): boolean {
        return this.info.confirmed || (this.type === "current" && !!this.info.availability);
      },
      async set(confirmed: boolean): Promise<void> {
        await this.$store.dispatch("review/confirm", {
          confirmed,
          level: this.level
        });
      }
    },
    onlineRequest: {
      get(): boolean {
        return this.info.onlineRequest || false;
      },
      async set(online: boolean): Promise<void> {
        await this.$store.dispatch("review/requestOnlineReview", {
          online,
          level: this.level
        });
      }
    },
    availability: {
      get(): string {
        return this.newAvailability || this.info.availability;
      },
      set(value: string) {
        this.newAvailability = value;
      }
    }
  },
  props: {
    info: {
      type: Object as () => ReviewRegistration,
      required: true
    },
    type: {
      type: String,
      default: "current"
    },
  },
  data() {
    return {
      newAvailability: "",
      editMode: false
    };
  },

  methods: {
    cancelEdit() {
      this.editMode = false;
      this.newAvailability = this.info.availability;
    },
    async saveExaminees() {
      await this.$store.dispatch("review/saveVolunteerExaminees", {
        amount: this.info.examineeLimit
      });
    },
    async saveIntent() {
      await this.$store.dispatch("review/saveVolunteerIntent", {
        intent: this.info.intent
      });
    },
    async saveAvailability() {
      await this.$store.dispatch("review/setAvailability", {
        message: this.newAvailability,
        level: this.level
      });
      this.editMode = false;
    }
  }
});
