
import Vue from "vue";

export default Vue.extend({
    name: "VideoModal",
    data() {
        return {
            player: {
                destroy: () => null
            }
        };
    },
    computed: {
        visible: {
            get(): boolean {
                return this.$store.state.modals.video.visible;
            },
            async set(visible: boolean): Promise<void> {
                await this.$store.dispatch("modals/set", {
                    type: "video",
                    visible
                });
            }
        },
        info(): any {
            return this.$store.state.modals.video.data || {};
        },
        title(): string {
            return `${this.$t("video.title.video", {
                level: this.info.level,
                part: this.info.part,
                video: this.info.index + 1
            })}`;
        }
    },
    methods: {
        playVideo() {
            this.player = new (window as any).Clappr.Player({
                source: this.info.link,
                parentId: "#player",
                width: 860,
                height: 485
            });
        },
        destroyVideo() {
            this.player.destroy();
        }
    }
});
