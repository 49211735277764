
import Vue from "vue";
import {download} from "@/utils";

export default Vue.extend({
  name: "VideosModal",
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.modals.videos.visible;
      },
      async set(visible: boolean) {
        await this.$store.dispatch("modals/set", {
          type: "videos",
          visible
        });
      }
    },
    info(): any {
      return  this.$store.state.modals.videos.data || { videos: [] };
    },
    title(): string {
      return  `${this.$t("video.title.videos", {
          level: this.info.level,
          part: this.info.index + 1
      })}`;
    }
  },
  methods: {
    async playVideo(index: number) {
      const {link} = await this.getVideoLink(index);
      await this.$store.dispatch("modals/set", {
        type: "video",
        visible: true,
        data: {
          index,
          link,
          level: this.info.level,
          part: this.info.index + 1
        }
      });
    },
    async getVideoLink(index: number) {
      // return await this.$store.dispatch("levels/getVideoLink", {
      //   filename: this.info.videos[index].path
      // });

        const prefix = this.info.videos[index].path.startsWith("week") ? "videos-cs106a-v2" : "videos-cs106a-v1";
        return { link: `https://stream.shpp.me/shppvod/_definst_/shpps3/${prefix}/${this.info.videos[index].path}/playlist.m3u8` };
    },
    async downloadVideo(index: number) {
      // const {link} = await this.getVideoLink(index);
      // console.log(link);
      // window.open(link, "_blank");
      // download(link); //, , link.split("/"), "video/mp4")

      const filename = this.info.videos[index].path || "";
      const {data: {data: {link}}} = await this.$store.dispatch("materials/getVideo", {
          level: this.info.level,
          filename
      });
      download(link, filename.split("/").pop() + "");

      // const a = document.createElement("a");
      //
      // a.href = link;
      // a.download = last(link.split("/"));
      // a.target = "_blank";
      // // console.log(last(link.split("/")));
      // // const iframe = document.createElement("iframe");
      // // iframe.setAttribute("src", link);
      // // iframe.setAttribute("style", "display: none");
      // // document.body.appendChild(iframe);
      // document.body.appendChild(a);
      // a.click();
      // console.log(a.download);
    }
  }
});
