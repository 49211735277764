
import Vue from "vue";
import config from "@/config";
import {systemAdminEmail} from "@/config";
import MarkedLink from "../MarkedLink.vue";
import { validateZipContents } from "@/utils";
import { PackageValidationResult, Survey } from "@/types";

export default Vue.extend({
  name: "HomeworkUploadModal",
  components: {
    MarkedLink
  },
  data() {
    return {
      survey: {
        questions: [] as Survey[],
        answers: [] as number[],
      },
      files: ([] as Array<{raw: File}>),
      status: "pending",
      step: 1,
      uploadErrorMessage: "",
      packageValidationResults: [] as PackageValidationResult[],
      packageIsValid: false,
      attempt: 0,
      attemptErrorVisible: false,
      errorMessage: "",
      packageValidationError: ""
    };
  },
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.modals.homeworkUpload.visible;
      },
      async set(visible: boolean): Promise<void> {
        await this.$store.dispatch("modals/set", {
          type: "homeworkUpload",
          visible
        });
      }
    },
    info(): any {
      return  this.$store.state.modals.homeworkUpload.data || {};
    },
    fileUploadLink(): string {
      return `${config.baseURL}${config.endpoints.upload_homework}`;
    },
    systemAdminEmail(): string {
      return systemAdminEmail;
    },
    uploadData() {
      return {
        level: this.info.level,
        reviewNumber: this.info.review,
        survey: JSON.stringify(this.survey.answers.map((id: number) => ({id, answer: true})))
      };
    },
    surveyIsValid() {
      return this.step !== 2 || JSON.stringify(this.survey.answers.sort()) ===
             JSON.stringify(this.survey.questions.filter((q) => q.positive).map((q) => q.id).sort());
    },
    maxSizeMb() { return [9, 4].includes(this.info.level) ? 5 : 1; },
    currentTitle() {
      switch (this.status) {
        case "pending":
          return this.$t("homeWorkUpload.title", {level: this.info.level}).toString();
        case "error":
          return this.$t("homeWorkUpload.error").toString();
        case "success":
          return this.$t("homeWorkUpload.success").toString();
        case "progress":
          return this.$t("homeWorkUpload.uploading").toString();
        default:
          return "";
      }
    }
  },
  methods: {
    async fillFiles(file: {raw: File}) {
      if ((this.files[0] || {raw: {}}).raw.lastModified !== file.raw.lastModified) {
        this.clearFile();
        this.files = [file];
        await this.validateFile(file.raw);
      }
    },
    submitUpload() {
      if (!this.files.length) {
        this.$message.error(this.$t("homeWorkUpload.warnings.emptyFile").toString());
        return;
      }
      if (this.step === 1) {
        if (this.packageIsValid) {
          this.step = 2;
        } else {
          this.attemptErrorVisible = true;
          // TODO: send request to backend
          this.attempt++;
        }
        return;
      }
      if (this.step === 2) {
        (this.$refs.upload as any).submit();
      }

    },
    async validateFile(file: File) {
      const sizeIsValid = this.validateFileSize(file);
      if (sizeIsValid) {
        if (this.info.level <= 3) {
          this.packageValidationResults = await validateZipContents(file, this.info.level);
          console.log(this.packageValidationResults);
          this.packageValidationError = (this.packageValidationResults.find((r: PackageValidationResult) => r.errors.length) || {errors: []}).errors[0];
          this.packageIsValid = !this.packageValidationError;
        } else {
          this.packageIsValid = true;
          this.step = 2;
        }
      }
    },
    validateFileSize(file: File) {
      const isMoreThan1M = file.size / 1024 / 1024 > this.maxSizeMb;
      const isZip = file.type === "application/zip"
        || file.type === "application/x-zip-compressed"
        || file.type === "" && /\.zip$/.exec(file.name);
      if (isMoreThan1M) {
        this.errorMessage = this.$t("homeWorkUpload.warnings.size").toString();
      }
      if (!isZip) {
        this.errorMessage = this.$t("homeWorkUpload.warnings.archiveType").toString();
      }

      return !isMoreThan1M && isZip;
    },
    async setStatus(status: string, e: Array<{message: string}> = [], cb?: () => void) {
      this.status = status;

      if (status === "success") {
        await this.$store.dispatch("levels/getExtendedLevel", this.info.level);
      }
      if (status === "progress") {
        (document.querySelector(".el-upload--text") as HTMLElement).style.display = "none";
      }
      if (status === "error") {
        const {error, data} = JSON.parse(e[0].message);
        switch (error.code) {
          case 421:
            this.status = "ddlExpired";
            break;
          case 401:
            window.location.href = data.redirectUrl;
            break;
          default:
            this.uploadErrorMessage = `Помилка: ${error.message} (${error.code})`;
        }
      }

      if (typeof cb === "function") {
        cb();
      }
    },
    clearFile() {
      this.files = [];
      this.attemptErrorVisible = false;
      this.errorMessage = "";
      this.step = 1;
    }
  },
  async mounted() {
    const {data} = await this.$store.dispatch("review/getHomeworkSurvey");
    this.$set(this.survey, "questions", data);
  }
});
